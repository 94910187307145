// import React from 'react'

// export default function ArticleMenuTemplate(props) {
//   return <div>{console.log(props)}</div>
// }

// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Flex, useThemeUI } from 'theme-ui'
import shortid from 'shortid'
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button' //Add this line Here

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================
import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/seo'

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================
import importPageComponents from '../helpers/importPageComponents'
import checkForCustomLinks from '../helpers/checkForCustomLinks'
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter'

// ?===================================
// ?========  Style Imports  ==========
// ?===================================
import '../../src/index.css'
import 'react-image-lightbox/style.css'
import ContactFormsContainer from '../components/ContactForm/ContactFormsContainer'
import ArticleMenu from '../components/Articles/ArticleMenu'

// This Template is used on every generated page on the site. apart from the multi location Landing page.
// its job is to get the data from the page context and graphql query and then funnel it to the right locations.
// data has to passed into two places. The Layout(i.e navigation and footer.) and the dyanmic component renderer
// this componenet also handle a dynamic theme color. passing the data into theme UI.

// ?===================================
// ?======  Template Component  =======
// ?===================================

const ArticleMenuTemplate = (props) => {
  // ?===========================
  // ?===== Component State =====
  // ?===========================

  const [components, setComponents] = useState(null) // where the pages componenets are stored after dynamically importing

  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext, data, location } = props
  const { businessData, poweredImages, blogTags } = data
  const configData = data.allConfigData.edges[0].node
  const { metaTitle, metaDescription } = configData
  const { name, desc, city, avatar, tags } = businessData

  // PAGE Context data that comes from gatsby node file gets desctructed from here
  const {
    // gonationID,
    pageObjectKey,
    // locationIndex,
    // city,
    pageComponents,
    customPageName,
    locations,
    isMultiLocationSite,
  } = pageContext

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme

  // this determines the page title used in the navigation.
  // if its custom page name use it. if not use the pageobject keywhich is the default name
  const pageTitle = `${
    customPageName && customPageName !== '' ? customPageName : pageObjectKey
  }`
  // const isMultiLocationSite = locations.length > 1

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // this gets the components for this page and dyanmically renders them and gives them all the props possible
  // so every componenet has access to any static data generated by the gatsy node files.
  const getComponents = async () => {
    // the components props data is passed in here
    const data = await importPageComponents(pageComponents, {
      ...pageContext,
      businessData,
      configData,
      poweredImages,
      pageTitle,
      isMultiLocationSite,
    })
    // once the components have been import and the promise has been finished set the components to the state.
    // ready for rendering
    setComponents(data)
  }

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts
    ? themeContext.fonts.googleFonts
    : ['Roboto'] // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies
    ? themeContext.fonts.customFamilies
    : ['']
  const customUrls = themeContext.fonts.customUrls
    ? themeContext.fonts.customUrls
    : ['']

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  useEffect(() => {
    getComponents() // on load of the template/page get the components

    if (typeof window !== `undefined`) {
      const WebFont = require('webfontloader') // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies,
        },
        custom: {
          families: customFamilies,
          urls: customUrls,
        },
      })
    }

    return () => {}
  }, [])

  const articleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'team':
        return 'allTeam'

      case 'press':
        return 'allPress'

      case 'general':
        return 'allGeneral'

      case 'policies':
        return 'allPolicies'

      case 'blog':
        return 'allBlog'

      default:
        break
    }
  }

  const articleVariantSelector = (pageObjectKey, page) => {
    const pageSelector = page === 'menu' ? 'Menu' : 'Page'

    switch (pageObjectKey) {
      case 'team':
        return `team${pageSelector}`

      case 'press':
        return `press${pageSelector}`

      case 'general':
        return `general${pageSelector}`

      case 'policies':
        return `policies${pageSelector}`

      case 'blog':
        return `blog${pageSelector}`

      default:
        break
    }
  }

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================
  console.log(pageContext)
  return (
    <Layout
      {...pageContext}
      navLinks={checkForCustomLinks(configData)}
      businessData={businessData}
      configData={configData}
      isMultiLocationSite={locations.length > 1}
      location={location}
    >
      <SEO
        title={
          metaTitle
            ? `${metaTitle} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
            : `${name} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
        }
        description={metaDescription ? `${metaDescription}` : `${desc}`}
        keywords={tags ? `${tags}` : `${[]}`}
        favicon={avatar.imageBaseUrl + '/' + avatar.imagePrefix}
      />

      <Flex sx={{ flexDirection: 'column' }} className='pageContainer'>
        {/* 
        //? The Page Contiainer Element Allows Us to Rearrange the contact form with flexOrder 
        */}
        {components
          ? components.map((Component) => {
              return <Component key={shortid.generate()} /> // * components are dyanmnically imported and rendered Here
            })
          : ''}

        <ArticleMenu
          businessData={businessData}
          configData={configData}
          articleData={data[articleDataSelector(pageObjectKey)]}
          pageObjectKey={pageObjectKey}
          customPageName={customPageName}
          variantName={articleVariantSelector(pageObjectKey, 'menu')}
          isMultiLocationSite={isMultiLocationSite}
          city={city}
          blogTags={blogTags?.tags}
        />

        {/*
        // * A Contact Form Has to Be Statically imported and Cannot By Dyanmically
        // *as netlify will not pick up the forms - To add Additional Forms Shoadow ContactFormsContainer
        */}
        <ContactFormsContainer
          {...pageContext}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
      </Flex>

      <ScrollUpButton className='scrollUpButton' style={scrollUpButtonStyles} />
    </Layout>
  )
}

export default ArticleMenuTemplate

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query articleTemplateQuery($city: String, $locationIndex: Int) {
    businessData(city: { eq: $city }) {
      ...businessDataFields
    }

    allConfigData {
      edges {
        node {
          ...allConfigDataFields
        }
      }
    }

    poweredImages {
      ...poweredImagesData
    }

    allBlog(
      sort: { order: DESC, fields: createdAt }
      filter: { locationIndex: { eq: $locationIndex } }
    ) {
      edges {
        node {
          ...blogArticleData
        }
      }
    }
    blogTags {
      id
      tags
    }
    allPress(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...pressArticleData
        }
      }
    }
    allTeam(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...teamArticleData
        }
      }
    }
    allGeneral(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...generalArticleData
        }
      }
    }
    allPolicies(filter: { locationIndex: { eq: $locationIndex } }) {
      edges {
        node {
          ...policiesArticleData
        }
      }
    }
  }
`

const scrollUpButtonStyles = {
  bottom: '0.5rem',
  left: '0.5rem',
  zIndex: '500',
  backgroundColor: 'rgb(255 255 255 / 45%)',
}
